import '~/assets/scss/styles.scss';

import React from 'react';
import getBrowserLanguage from './src/utils/getBrowserLanguage';
import { AppProvider } from './src/context';

export const wrapRootElement = ({ element, pathname }) => {
  return <AppProvider pathname={window.location.pathname}>{element}</AppProvider>;
};

export const onInitialClientRender = () => {

  const ACTIVE = false;

  const browserLang = getBrowserLanguage();
  if (ACTIVE === false || typeof window === 'undefined' || !browserLang) return;

  const { pathname } = window.location;
  const langs = ['en', 'ja', 'zh-hans'];

  const localisedLangs = langs.filter((lang) => lang !== 'en');
  const currentPathLang = localisedLangs.filter((lang) => pathname.startsWith(`/${lang}/`))[0];

  if (currentPathLang === browserLang) return;
  // currently on localised page but need to redirect to english version
  if (browserLang === 'en' && currentPathLang && currentPathLang !== browserLang) {
    window.location.pathname = `/${pathname.replace(`/${currentPathLang}/`, '')}`;
  }

  // Trying to redirect to a lang with no translated content
  if (!localisedLangs.includes(browserLang)) return;

  // Must currently be on english version
  if (!currentPathLang) {
    window.location.pathname = `/${browserLang + window.location.pathname}`;
  } else {
    window.location.pathname = `/${browserLang + window.location.pathname.replace(`/${currentPathLang}/`, '/')}`;
  }
};
