import React, { createContext, useEffect, useState } from 'react';
import { globalHistory } from '@reach/router';

export const AppContext = createContext<{
  locale: 'en' | 'ja' | 'zh-hans' | string;
  prefix: string;
  options: object;
}>({ locale: 'en', prefix: '/', options: {} });

export const AppProvider = ({ children, pathname = '' }: any) => {
  const [locale, setLocale] = useState('en');
  const [prefix, setPrefix] = useState('/');
  const [options, setOptions] = useState({});

  useEffect(()=>{
    if (typeof window !== 'undefined') {
      try {
        const winLocale = window.location.pathname.split('/').filter(Boolean)[0];
        if (['ja', 'zh-hans'].includes(winLocale)) {
          setLocale(winLocale);
        }
      } catch (error) {
        console.log(error);
      }
    }
  },[])

  const setLocaleByPathname = (pathname = '') => {
    if (pathname === '/ja' || pathname.startsWith('/ja/')) {
      setLocale('ja');
    } else if (pathname === '/zh-hans' || pathname.startsWith('/zh-hans/')) {
      setLocale('zh-hans');
    } else {
      setLocale('en');
    }
  };

  useEffect(() => {
    setLocaleByPathname(pathname);
  }, [pathname]);

  useEffect(() => {
    return globalHistory.listen((props) => {
      if (props.action === 'PUSH' || props.action === 'POP') setLocaleByPathname(props.location.pathname);
    });
  }, []);

  // Get correct options page
  useEffect(() => {
    fetch(`https://opengammacms.wpengine.com/wp-json/together/options?lang=${locale}&v=5`)
      .then((res) => res.json())
      .then((data) => {
        setOptions(data);
      });
  }, [locale]);

  // Get correct options page
  useEffect(() => {
    setPrefix(locale === 'en' ? '/' : `/${locale}`);
  }, [locale]);

  return (
    <AppContext.Provider
      value={{
        locale,
        prefix,
        options,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
